import {  useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RestAPI } from "@/utils/api-rest";
import { toast } from "react-toastify";

import withName from "../../hoc/withName";
import LoadingDialog from "../../components/Utils/LoadingDialog";
import { Menubar } from "primereact/menubar";

const Invitation = ({initialLoad}) => {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);

    useEffect(() => {
        const getData = async (link) => {
            const param = "/users/acceptInvitation";
            await RestAPI.post("apiAdminDropControlNoAuth",param,
                {body: {"link":link}}).then(async (response) => {
                setResponse(response);
                setIsLoading(false);
                toast.success(t("invitationPage.msgSuccess"), { autoClose: 5000 });
            })
                .catch((error) => {
                    console.log(error);
                    if(error?.response?.data?.expired){
                        setError("expired");

                    }else{
                        setError("default");
                    }
                    setIsLoading(false);
                    toast.error(t("forms.toastError"), { autoClose: 5000 });
                });
        };
        if(response === null && error === null && initialLoad){
            getData(window?.location?.pathname?.substring("/invitation/".length));
            setIsLoading(true);
        }
    },[response, error, initialLoad]);

    const itemAction = ({item}) => {
        if (!item || !item.action) return;
        const match = item.action.match(/^([^-]+)-(.*)/);
        if (i18n.language === match[2]) return;

        setTimeout(() => {
            i18n.changeLanguage(match[2]);
        }, 0);
    };
    const langItemPrime = {
        icon: "world-icon",
        label: t("i18n.current"),
        items: [
            { label: t("i18n.english"), action: "lang-en", command:itemAction},
            { label: t("i18n.spanish"), action: "lang-es", command:itemAction },
            { label: t("i18n.italian"), action: "lang-it", command:itemAction },
            { label: t("i18n.turkish"), action: "lang-tr" , command:itemAction},
            { label: t("i18n.french"), action: "lang-fr", command:itemAction },
        ]
    };
    return (
        <>
            <div className="header-section" id="header">
                <ul className="header-list">
                    <li id="hamburger" className="icon-menu icon" />
                    <div className="header-title d-none d-lg-block" />
                    <div className="header-title-small d-block d-lg-none" />
                    <div className="header-title-muy-small d-block" />

                    <li className="right-header">
                        <div className="horizontal-menu">
                            <>
                                <Menubar className="header-popup d-none d-md-block primeMenuBar"
                                    model={[{...langItemPrime}]}
                                />
                                <Menubar menuIcon={<span className='pi pi-bars menuIcon' />} className="header-popup-small d-block d-md-none primeMenuBar"
                                    model={[{...langItemPrime, icon: langItemPrime.icon + " small"}]}
                                />
                            </>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='row-container'>
                {isLoading && <LoadingDialog />}
                <div className='row'>
                    <div className="col-xl-12 col-lg-12 col-sm-12">
                        <div id="cardRaw" className="card">
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-xl-3 col-lg-12 col-sm-12" />
                                    <div className="col-xl-6 col-lg-12 col-sm-12 text-center">
                                        <h5 className="card-title"><span>{t("invitationPage.title")}</span></h5>
                                    </div>
                                    <div className='col-xl-3 col-lg-12 col-sm-12 d-flex justify-content-end align-items-right align-items-center' />
                                </div>
                                <div className='linea-titulo' />
                                {
                                    response?.farm?
                                        <>
                                            <div className='row'>
                                                <div className="col-xl-12 col-lg-12 col-sm-12 text-center">
                                                    <i className="pi pi-check-circle" style={{ fontSize: "2.5rem", color:"#579C27" }} />
                                                </div>
                                                <div className="col-xl-12 col-lg-12 col-sm-12 text-center">
                                                    <p>{t("invitationPage.msg1")} <b>{` ${response?.farm?.nombre_campo??"farmName"} `}</b> {t("invitationPage.msg2")}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-xl-12 col-lg-12 col-sm-12 text-center">
                                                    <p>{t("invitationPage.msg3")}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-xl-12 col-lg-12 col-sm-12 text-center">
                                                    <span className="invitation dropcontrol-icon" onClick={() => globalThis.window.open(`https://www.dropcontrol.com/site/dashboard/campo.do?cuentaId=${response?.farm?.cuenta_id}&campoId=${response?.farm?.campo_id}`,"_blank")} />
                                                </div>
                                            </div>
                                            {
                                                response?.insertRes?.tipousuario_id ===12?
                                                    null
                                                    :<div className='row'>
                                                        <div className="col-xl-12 col-lg-12 col-sm-12 text-center">
                                                            <span className="invitation admin-icon-oscuro" onClick={() => globalThis.window.location.href= `https://${response?.stage??""}admin.dropcontrol.com/farms?farm=${response?.farm?.campo_id}`  } />
                                                        </div>
                                                    </div>
                                            }
                                        </>
                                        :null
                                }
                                {
                                    error==="default"?
                                        <div className='row'>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 text-center">
                                                <i className="pi pi-times-circle" style={{ fontSize: "2.5rem", color:"tomato" }} />
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 text-center">
                                                <p>{t("invitationPage.msgDefault")}</p>
                                            </div>
                                        </div>
                                        :null
                                }
                                {
                                    error==="expired"?
                                        <div className='row'>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 text-center">
                                                <i className="pi pi-exclamation-triangle" style={{ fontSize: "2.5rem", color:"orange" }} />
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-sm-12 text-center">
                                                <p>{t("invitationPage.msgExpired")}</p>
                                            </div>
                                        </div>
                                        :null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withName(Invitation, "InvitationPage");
