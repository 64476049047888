import { fetchAuthSession } from "aws-amplify/auth";
import { Amplify } from "aws-amplify";
import wiseExports from "./wiseconn-exports.js";

const tokenCache = { pending: null };
export const clearCache = () => {
    tokenCache.pending = null;
};

const getSessionToken = async (refresh) => {
    const refreshedSession = await fetchAuthSession({ forceRefresh: refresh });
    return refreshedSession.tokens?.idToken;
};

const getTokenInner = async () => {
    let token;
    try {
        token = await getSessionToken(false);
    } catch {
        token = await getSessionToken(false); // retry once
    }

    return token.toString();
};

const getToken = async () => {
    if (tokenCache.pending) tokenCache.pending;

    const thisProm = tokenCache.pending = getTokenInner();
    try {
        return (await thisProm);
    } finally {
        tokenCache.pending = null;
    }
};

Amplify.configure(wiseExports, {
    API: {
        REST: {
            headers: async ({ apiName }) => {
                if (apiName === "apiAdminDropControl") return { Authorization: `Bearer ${await getToken()}` };
                return {};
            },
        },
    },
});
