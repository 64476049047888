import { createContext, useMemo, useRef, useState } from "react";
import { applyFormulas } from "../utils/area";

export const FarmContext = createContext([]);
const AREA_FORMAT_KEY = "number.dec.4";

// This component will be added with a known name in the ErrorBoundary componentStack
const TracePoint = (props) => props && props.children;
TracePoint.displayName = "FarmRoot";

const FarmProvider = ({ children }) => {

    const [farmState, setFarmState] = useState({ loading: true, data: null, dataFarm: null, original: null, users: null, saas: null, formated: false });
    const lastUpdate = useRef({});
    const loadedState = useRef({ data: farmState });
    if (!Object.is(farmState, loadedState.current.data)) {
        const original = farmState && (farmState.original ?? {});
        const networkArray = [];
        if (Array.isArray(original && original.network) &&
      Array.isArray(original.nodes)) {
            original.network.forEach((gwId) => {
                const gw = original.nodes.find(nodo => nodo.nodo_id === gwId);
                if (!gw) return;
                gw.subNodes = [];
                networkArray.push(gw);

                if (Array.isArray(gw.childs)) {
                    gw.childs.forEach((child) => {
                        const childNode = original.nodes.find(n => n.nodo_id === child);
                        //if (!childNode || childNode.c1_v1) return;
                        if (childNode) gw.subNodes.push(childNode);
                        if (Array.isArray(childNode.v1_childs)) {
                            childNode.subNodes = childNode.v1_childs.map((v1_child) => {
                                const v1_childNode = original.nodes.find(n => n.nodo_id === v1_child);
                                return v1_childNode;
                            });
                        }
                    });
                }
            });
        }
        loadedState.current = { data: farmState, networkArray };
    }

    const dispatch = useMemo(() => {
        const updateData = (action, array, dictName, keyName, cleanName, areaInfo, subChild) => {
            const updatedData = action.params;
            if (!Array.isArray(array) || !Array.isArray(updatedData)) return array;

            let obj = undefined, modified = false;
            updatedData.forEach((newData) => {
                const key = newData[keyName];
                if (subChild) {
                    /*
          for (let i = 0; i < array.length; ++i) {
            const object = array[i];
            const subArray = object && object[subChild];
            if (!subArray) continue;

            obj = subArray.find((oldData) => oldData[keyName] === key);
            if (obj) break;
          }
          */
                } else {
                    obj = array.find((oldData) => oldData[keyName] === key);
                }

                if (obj) {
                    if (cleanName && newData.nombre) newData.nombre = newData.nombre.split("[")[0].trim();
                    if (areaInfo && newData.sectors) {
                        const { formula_from_kilo_base, formula_to_base } = areaInfo;
                        newData.sectors.forEach(s => {
                            if (s.superficie !== undefined) s.apiArea = s.superficie;
                            if (s.superficie && formula_from_kilo_base && formula_to_base) {
                                s.superficie = applyFormulas(s.superficie / 10000, formula_to_base, formula_from_kilo_base, AREA_FORMAT_KEY);
                            }
                        });
                        updateData({ params: newData.sectors, skipReload: true }, array, null, "sector_id");
                        delete newData.sectors;
                    }
                    Object.assign(obj, newData);
                    modified = true;
                }
            });

            if (modified && !action.skipReload) {
                const dataRef = loadedState.current;
                if (dictName) delete dataRef[dictName];
                // recreate the "original" object so its re-formated
                setFarmState(f => ({ ...f, original: { ...f.original }, formated: false }));
            }
            return array;
        };
        return (action) => {
            const dataRef = loadedState.current;
            const { loading, dataFarm, users, saas } = dataRef.data || {};
            const original = dataRef?.data?.original ?? {};
            switch (action.type) {
                case "get": {
                    switch (action.path) {
                        case "canEdit": return dataFarm && dataFarm.can_edit;
                        case "canAccessComparams": return dataFarm && dataFarm.can_access_comparams;
                        case "canAutorizarApi": return dataFarm && dataFarm.can_autorizar_api;
                        case "accesoLimitado": return dataFarm && dataFarm.acceso_limitado;
                        case "saas": return { data: saas, isLoaded: !loading };
                        case "units": return dataFarm && dataFarm.areaInfo;
                        case "users": return { data: users, isLoaded: !loading };
                        case "network": return dataRef.networkArray;
                        case "nodes": return original?.nodes;
                        case "seasons": return original?.seasons??[];
                        case "nodesDict":
                            if (!dataRef.nodesDict && Array.isArray(original && original.network)
                                    && Array.isArray(original && original.nodes)) {
                                dataRef.nodesDict = original.network.reduce((result, gwId) => {
                                    const gw = original.nodes.find(nodo => nodo.nodo_id === gwId);
                                    if (gw && gw.nodo_id && gw.tipo) result[gw.nodo_id] = gw;
                                    if (Array.isArray(gw && gw?.v1_childs)) {
                                        gw.v1SubNodes = gw.v1_childs.map((v1_child) => {
                                            const v1_childNode = original.nodes.find(n => n.nodo_id === v1_child);
                                            return v1_childNode;
                                        });

                                    }
                                    Array.isArray(gw && gw.v1SubNodes) && gw.v1SubNodes.forEach((nodo) => {
                                        if (nodo.nodo_id) {
                                            if (!nodo.parent) nodo.parent = gw.nodo_id;
                                            result[nodo.nodo_id] = nodo;
                                        }
                                    });
                                    Array.isArray(gw && gw.subNodes) && gw.subNodes.forEach((nodo) => {
                                        if (nodo.nodo_id) {
                                            if (!nodo.parent) nodo.parent = gw.nodo_id;
                                            result[nodo.nodo_id] = nodo;
                                        }
                                    });
                                    return result;
                                }, {});
                            }
                            if (dataRef.nodesDict === undefined) dataRef.nodesDict = null;
                            return dataRef.nodesDict;
                        case "sectors": return original && original.sectores;
                        case "sectorsDict":
                            if (!dataRef.sectorsDict && Array.isArray(original && original.sectores)) {
                                const area_sufix = (dataFarm && dataFarm.areaInfo) ? dataFarm.areaInfo.kilo_unidad : "";
                                dataRef.sectorsDict = original.sectores.reduce((result, s) => {
                                    if (s.sector_id) result[s.sector_id] = s;
                                    return result;
                                }, { area_sufix });
                            }
                            if (dataRef.sectorsDict === undefined) dataRef.sectorsDict = null;
                            return dataRef.sectorsDict;
                        case "sensors":
                            if (!dataRef.sensorsList && Array.isArray(original && original.nodes)) {
                                dataRef.sensorsList = original.nodes.reduce((result, n) => {
                                    if (Array.isArray(n.sensores)) n.sensores.forEach((s) => s && result.push(s));
                                    return result;
                                }, []);
                            }
                            if (dataRef.sensorsList === undefined) dataRef.sensorsList = null;
                            return dataRef.sensorsList;
                        case "pumpsys": return original && original.equipos;
                        case "pumpsysDict":
                            if (!dataRef.pumpsysDict && Array.isArray(original && original.equipos)) {
                                dataRef.pumpsysDict = original.equipos.reduce((result, equipo) => {
                                    if (equipo.equipo_id) result[equipo.equipo_id] = equipo;
                                    return result;
                                }, {});
                            }
                            if (dataRef.pumpsysDict === undefined) dataRef.pumpsysDict = null;
                            return dataRef.pumpsysDict;
                        case "hidrasDict":
                            if (!dataRef.hidrasDict && Array.isArray(original && original.equipos)) {
                                dataRef.hidrasDict = original.equipos.reduce((result, equipo) => {
                                    equipo && equipo.hidraulicas.forEach((hidraulica) => {
                                        if (hidraulica.hidraulica_id) result[hidraulica.hidraulica_id] = hidraulica;
                                    });
                                    return result;
                                }, {});
                            }
                            if (dataRef.hidrasDict === undefined) dataRef.hidrasDict = null;
                            return dataRef.hidrasDict;
                        case "mapa_json": return dataFarm?.mapa_json;
                        case "fuentes_coords": return original?.fuentes?.filter(f => f.position !== null);
                        case "fenologia": return dataFarm?.fenologia;
                        case "paletasFenologia": return dataFarm?.paletasFenologia;
                        default: return;
                    }
                }
                // return true if still needs to get the iniial data, or its getting it
                case "check": return (lastUpdate.current.param !== action.params) || (lastUpdate.current.expire > Date.now());
                case "mark": {
                    lastUpdate.current.expire = Date.now() + (10 * 1000); // 3 secs
                    lastUpdate.current.param = action.params;
                    return true;
                }
                case "set": return setFarmState(action.params);
                case "update": {
                    switch (action.path) {
                        case "nodes": return updateData(action, original && original.nodes, "nodesDict", "nodo_id", true);
                        case "sectors": return updateData(action, original && original.sectores, "sectorsDict", "sector_id", false, dataFarm && dataFarm.areaInfo || {});
                            //case 'actuators': return updateData(action, original && original.nodes, null, "actuador_id", false, "actuadores");
                            //case 'sensors': return updateData(action, original && original.nodes, null, "sensor_id", false, "sensores");
                        case "pumpsys": return updateData(action, original && original.equipos, "pumpsysDict", "equipo_id");
                            //case 'hydraulics': return updateData(action, original && original.equipos, "hidrasDict", "hidraulica_id", false, false, "hidraulicas");
                        case "users": {
                            const p = action.params;
                            if (typeof p === "function") {
                                setFarmState(f => {
                                    const action = p({ data: users });
                                    if (action === undefined) return f;
                                    if (action === null) return { ...f, original: { ...f.original }, formated: false };
                                    return { ...f, users: action.data, original: { ...f.original }, formated: false };
                                });
                            } else if (p) {
                                const newState = {};
                                if (p.isLoaded !== undefined) newState.loading = !p.isLoaded;
                                if (p.data !== undefined) {
                                    newState.users = p.data;
                                    setFarmState(f => ({ ...f, ...newState, original: { ...f.original }, formated: false }));
                                } else {
                                    setFarmState(f => ({ ...f, ...newState }));
                                }
                            }
                            return;
                        }
                        case "saas": {
                            const p = action.params;
                            if (p) {
                                const newState = {};
                                if (p.isLoaded !== undefined) newState.loading = !p.isLoaded;
                                if (p.data !== undefined) {
                                    newState.saas = p.data;
                                    setFarmState(f => ({ ...f, ...newState, original: { ...f.original }, formated: false }));
                                } else {
                                    setFarmState(f => ({ ...f, ...newState }));
                                }
                            }
                            return;
                        }
                        default: return;
                    }
                }
                default: return;
            }
        };
    }, []);

    return (
    // this will cause a re-render of all components that use this context on each context re-render,
    // because the array is newly-created each time
    // eslint-disable-next-line react/jsx-no-constructed-context-values
        <FarmContext.Provider value={[farmState, dispatch]}>
            <TracePoint>{children}</TracePoint>
        </FarmContext.Provider>
    );
};

export default FarmProvider;
