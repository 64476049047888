import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ButtonPrime from "../PrimeComponents/ButtonPrime";
import { useNavigate } from "react-router";

const ErrorPage =  ({error, isRouteError}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
        <div className='row-container'>
            <div className='row'>
                <div className="col-xl-12 col-lg-12 col-sm-12" >
                    <div className="card">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col col-sm-2 col-md-3 col-lg-4 d-flex justify-content-start align-items-center' />
                                <div className='col col-sm-8 col-md-6 col-lg-4 text-center'>
                                    <h5 className="card-title">
                                        {t("error.msg")}
                                    </h5>
                                </div>
                            </div>
                            <div className='linea-titulo' />
                            <div className='text-center' style={{marginBottom: "20px"}} >
                                {
                                    isRouteError&&error?.statusText?
                                        t("errorPage.route."+error.statusText)
                                        :t("error.unavailable")
                                }
                            </div>
                            <div className="col-12 text-center">
                                <ButtonPrime onClick={() => navigate(-1)} label={t("marketplace.return")} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
