import { useEffect, useMemo, useRef, useState } from "react";
import { /*appendToCognitoUserAgent,*/ fetchAuthSession, signOut } from "aws-amplify/auth";
import TagManager from "react-gtm-module";

import { ConsoleLogger, Hub } from "@aws-amplify/core";
import { AuthenticatorContext } from "../contexts/AuthenticatorContext";
import Invitation from "../pages/Invitation/Invitation";
import { clearCache } from "../amplifyConfig";

const logger = new ConsoleLogger("withAuthenticator");

const getEmailParam = (location) => {
    const search = new URLSearchParams(location && location.search);
    return search.get("email");
};

const MIN_DELAY = 400;

// Mostly taken from
// https://github.com/aws-amplify/amplify-js/blob/aws-amplify%403.3.11/packages/amplify-ui-react/src/withAuthenticator.tsx
export default function withAuthenticator(App, Authenticator, Loading) {
    const AppWithAuthenticator = props => {
        // we dont want the user to see a loading screen for a couple ms.
        // force it for half a second (at least) if is not logged in
        // (if it's logged in, we dont really care, since it will need to load some extra stuff anyways)
        const [extraDelayEnded, setExtraDelayEnded] = useState(() => {
            new Promise(resolve => {
                setTimeout(() => resolve(), MIN_DELAY);
            }).then(() => setExtraDelayEnded(true));
            return false;
        });
        const [initialLoad, setInitialLoad] = useState(false);

        const emailParam = getEmailParam(window.location);
        const dcEmail = useRef(emailParam);
        const [signedInInfo, setSignedInInfo] = useState({ info: {}, in: false, dcEmail: emailParam });
        const userInfoRef = useRef({});
        if (userInfoRef.current !== signedInInfo.info) userInfoRef.current = signedInInfo.info;

        const { setUser, checkUser } = useMemo(() => {
            const setUser = async (forceRefresh) => {
                try {
                    // START GET USER DATA
                    let userPayload;
                    try {
                        const tokens = (await fetchAuthSession({ forceRefresh })).tokens;
                        userPayload = tokens.idToken.payload;
                    } catch(err) {
                        userPayload = null;
                        console.debug(err);
                    }

                    if (!userPayload) {
                        // force refresh
                        const tokens = (await fetchAuthSession({ forceRefresh: true })).tokens;
                        userPayload = tokens?.idToken?.payload;
                    }

                    // clear amplify cache (token)
                    clearCache();
                    // END GET USER DATA

                    if (userPayload) {
                        const attributes = userPayload;
                        const thisEmail = attributes && attributes.email;
                        if (dcEmail.current && dcEmail.current !== thisEmail) {
                            clearCache();
                            signOut().finally(() => {
                                clearCache();
                            });
                        } else if (attributes && ((attributes.sub && userInfoRef.current.sub !== attributes.sub)
                            || (attributes.name && userInfoRef.current.name !== attributes.name)
                            || (attributes.family_name && userInfoRef.current.family_name !== attributes.family_name))
                        ) {
                            setSignedInInfo({ info: { ...attributes }, in: true, dcEmail: null });
                        } else {
                            setSignedInInfo((si) => si.in ? si : { ...si, in: true, dcEmail: null });
                        }
                        dcEmail.current = null;
                        document.body.classList.remove("all");

                        return attributes;
                    }
                }
                catch (err) {
                    logger.debug(err);
                }
            };

            const checkUser = () => {
                setUser().then(() => setInitialLoad(true));
                return Hub.listen("auth", async (info) => {
                    const { payload } = info;
                    const { event, data } = payload || {};

                    if (event ===  "signedOut") {
                        clearCache();
                        setSignedInInfo((si) => ({ info: {}, in: false, dcEmail: si.dcEmail }));
                        TagManager.dataLayer({
                            dataLayer: {
                                event: "logout",
                                user_email: null,
                                user_profile: null,
                                user_es_sistema: null,
                                user_type: null
                            }
                        });
                    } else if (event === "signedIn") {
                        dcEmail.current = null; // jut to be sure there is no case when setUser() closes the session
                        setUser().then((attributes) => {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: "login",
                                    user_email: attributes?.email,
                                    user_profile: attributes?.profile,
                                    user_es_sistema: null,
                                    user_type: null
                                }
                            });
                        });
                    } else if (event === "tokenRefresh_failure") {
                        clearCache();
                        signOut().finally(() => {
                            clearCache();
                        });
                    } else if (event === "customOAuthState" && data) {
                        // im not really sure we we are setting this here instead of on click of the login button
                        globalThis.sessionStorage.setItem("loginUrl", data);
                    }
                });
            };
            return { setUser, checkUser };
        }, [setSignedInInfo, setInitialLoad]);

        useEffect(() => {
            //appendToCognitoUserAgent("wiseconn withAuthenticator");

            // checkUser returns an "unsubscribe" function to stop side-effects
            return checkUser();
        }, [checkUser]);

        const contextValue = useMemo(() => {
            async function signOutInner() {
                try {
                    window.document.body.classList.remove("overflow-hidden");
                    await signOut().then(console.log("Signed out"));
                } catch (err) {
                    console.log("Unexpected error in signout");
                }
            }

            return { refreshUser: setUser, signOut: signOutInner, userInfo: signedInInfo.info };
        }, [setUser, signedInInfo]);

        //BYPASS AUTH ROUTES
        if(window?.location?.pathname.startsWith("/invitation")){
            return(
                <Invitation initialLoad={initialLoad} />
            );
        }
        const email = signedInInfo.info?.email;
        if (!signedInInfo.in) {
            return (
                <>
                    <Loading hide={!initialLoad || extraDelayEnded} />
                    {initialLoad ? <Authenticator dcEmail={signedInInfo.dcEmail} {...props} /> : null}
                </>
            );
        }
        return (
            <AuthenticatorContext.Provider value={contextValue}>
                <App key={email} />
            </AuthenticatorContext.Provider>
        );
    };
    return AppWithAuthenticator;
}
