import { useEffect, useMemo } from "react";
import { useMatches, useRouteError, isRouteErrorResponse } from "react-router";

import { v1 as uuidv1 } from "uuid";
import { getCache } from "@/utils/logcache";

import LoadingDialog from "./LoadingDialog";
import { ShowError, sendError } from "./ShowError";
import ErrorPage from "./ErrorPage";

const GenericBoundary = ({ error, name }) => {
    const matches = useMatches();
    const { cache, uuid, time } = useMemo(() => (
        { cache: getCache(), uuid: uuidv1(), time: new Date() }
    ), [error]);

    if (error) {
        error.cache = cache;
        if (!error.uuid) error.uuid = uuid;
        error.matches = matches.map(({ id, params, pathname }) => JSON.stringify({ id, params, pathname })).join("\n");
    }

    useEffect(() => {
        sendError(error, name, error.uuid, error.cache, error.matches);
    }, [error]);

    return <ShowError resetOnLocationChange={false} uuid={error.uuid} time={time} />;
};

const ErrorElement = ({ name }) => {
    const error = useRouteError();
    if (isRouteErrorResponse(error)) {
        if(error?.statusText){
            return <ErrorPage error={error} isRouteError={isRouteErrorResponse(error)} />;
        }else{
            return <LoadingDialog />; // todo: different msg for route errors?
        }
    } else {
        return <GenericBoundary error={error} name={name} />;
    }
};

export { ErrorElement };
