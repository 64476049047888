import { get, post, put } from "aws-amplify/api";

const RestAPI = {
    /**
   * @param apiName {String}
   * @param path {String}
   * @param options {Parameters<typeof get>[0]["options"]}
   * */
    get: async (apiName, path, options) => (
        await get({ apiName, path, options }).response
    ).body.json(),
    /**
   * @param apiName {String}
   * @param path {String}
   * @param options {Parameters<typeof post>[0]["options"]}
   * */
    post: async (apiName, path, options) => (
        await post({ apiName, path, options }).response
    ).body.json(),
    /**
   * @param apiName {String}
   * @param path {String}
   * @param options {Parameters<typeof put>[0]["options"]}
   * */
    put: async (apiName, path, options) => (
        await put({ apiName, path, options }).response
    ).body.json()
};

export { RestAPI };
