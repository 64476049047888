import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useRevalidator, useRouteLoaderData } from "react-router";
import { RestAPI } from "@/utils/api-rest";

import mundo from "../../img/world_icon.png";

const printElement = (elem) => {
    const domClone = elem.cloneNode(true);
    const printSection = document.createElement("div");
    printSection.id = "printSection";
    document.body.appendChild(printSection);

    printSection.innerHTML = "";
    printSection.appendChild(domClone);
};

const createPrintFn = (id) => {
    return () => {
        printElement(document.getElementById(id));
        window.print();
        const createdElm = document.getElementById("printSection");
        createdElm?.parentNode.removeChild(createdElm);
    };
};

const acceptCook = async (email, navigate, revalidator) => {
    try {
        const param = "/personal/dropcontrolpolicies";
        const data = await RestAPI.post("apiAdminDropControl", param, { body: email });
        //const data = [{ updt: true }];
        if (data[0] && data[0].updt) {
            const path = "/personal";
            navigate({ pathname: path });
            revalidator.revalidate();
            return data[0].updt;
        } else {
            return false;
        }
    }
    catch (err) {
        console.error("error", err);
    }
};

// eslint-disable-next-line no-empty-pattern
const PolicyAndTerms = ({ }) => {
    const { t, i18n } = useTranslation();
    const checkRef1 = useRef(null);
    const checkRef2 = useRef(null);
    const btnRef = useRef(null);

    const usuarioInfo = useRouteLoaderData("root");
    const navigate = useNavigate();
    const revalidator = useRevalidator();
    const email = usuarioInfo.email;

    const changeLang = () => {
        if (t("login.lang") === "English") {
            setTimeout(() => {
                i18n.changeLanguage("en");
            }, 0);
        } else {
            setTimeout(() => {
                i18n.changeLanguage("es");
            }, 0);
        }
    };

    return (
        <>
            {i18n.language === "es" ? (
                <div id="container-login">
                    <div id="container">
                        <div className='nav-wrapper'>
                            <nav>
                                <ul className='nav'>
                                    <li className='langSelector' style={{ cursor: "pointer" }}>
                                        <a href="#en_US" onClick={changeLang}><img alt="change-language" src={mundo} />{t("login.lang")}</a>
                                    </li>
                                    <li>
                                        <a href="//www.wiseconn.com/contact" target="_blank" rel="noreferrer">{t("login.contacto")}</a>
                                    </li>
                                    <li>
                                        <a href="//www.wiseconn.com" target="_blank" rel="noreferrer">Wiseconn</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div id="terms-container" className="terms-wrapper text">
                            <div>
                                <span className="title" >Política de Privacidad de WiseConn</span>
                            </div>
                            <span className="mini-boldie-text">ENERO 2023</span>
                            <p>
                                Tu privacidad es importante para nosotros y WiseConn se esfuerza por
                                respetar tu privacidad con respecto a cualquier información que podamos
                                recopilar de usted a través del sistema DropControl o a través
                                de nuestro sitio web (el "Servicio"). DropControl es un sistema
                                compuesto por una plataforma web, sitios web, aplicaciones móviles,
                                dispositivos de campo y servicios API (sigla en inglés para: Interfaz
                                de Programación de Aplicaciones) que permite monitorear y analizar, en
                                tiempo real, información vital para la gestión agrícola, y así, tomar
                                decisiones oportunas que permitan la optimización de los recursos en el
                                campo agrícola. Esta página le informa sobre nuestras políticas
                                relativas a la recopilación, uso y divulgación de datos personales
                                cuando utiliza nuestro Servicio y las opciones que tiene asociadas a
                                dichos datos. A menos que se defina lo contrario en esta Política de
                                Privacidad, los términos utilizados en esta Política de Privacidad
                                tienen los mismos significados que en nuestros{" "}
                                <a href="https://www.dropcontrol.com/public/terms.jsp" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>.
                            </p>
                            <p>
                                Si tiene alguna pregunta sobre cómo manejamos los Datos Personales de
                                nuestros usuarios, no dude en contactarnos en <a href="mailto:soporte@wiseconn.cl" target="_blank" rel="noopener noreferrer">soporte@wiseconn.cl</a>.
                            </p>
                            <span className="sub-title" >Lo que Cubre nuestra Política de Privacidad</span>
                            <p>
                                Esta Política de Privacidad contiene información importante y
                                revelaciones relacionadas con la forma en que tratamos los Datos
                                Personales que obtenemos cuando tu accedes o utilizas nuestro sitio
                                web. WiseConn puede contener enlaces a otros sitios web de interés. Sin
                                embargo, una vez que hayas utilizado estos enlaces para salir de
                                nuestro sitio, debes tener en cuenta que no tenemos ningún control
                                sobre aquellos. Por lo tanto, no nos hacemos responsables de la
                                protección y la privacidad de cualquier información que tú proporcione
                                mientras visita dichos sitios, ya que no se rigen por esta declaración
                                de privacidad. Debes tener cuidado y consultar la política de
                                privacidad aplicable al sitio web en cuestión. Por favor, ten en cuenta
                                que aquellos sitios pueden tener derechos adicionales para controlar el
                                uso y la divulgación de tus Datos Personales. Los residentes de
                                California deben consultar las subsecciones pertinentes a continuación.
                                Puedes imprimir una copia de esta Política de Privacidad haciendo clic{" "}
                                <a href="javascript:void(0)" onClick={createPrintFn("terms-container")}>aquí</a>. Si tiene una discapacidad, puede acceder a esta
                                Política de Privacidad en un formato alternativo contactando a <a href="mailto:soporte@wiseconn.cl" target="_blank" rel="noopener noreferrer">soporte@wiseconn.cl</a>.
                            </p>
                            <span className="sub-title">Recopilación y Uso de Información</span>
                            <p>Recopilamos varios tipos de información con diferentes propósitos
                                para proporcionar y mejorar nuestro servicio. El uso del término "Datos
                                Personales" en este documento significa cualquier información que
                                identifique o se relacione con un individuo en particular y también
                                incluye información definida como "información personal identificable"
                                o "datos personales" bajo las leyes de privacidad de datos, reglas o
                                regulaciones aplicables. El cuadro que figura a continuación detalla
                                las categorías de Datos Personales que recopilamos y cómo utilizamos
                                los datos.
                            </p>

                            <table className="terms-categories">
                                <tbody>
                                    <tr>
                                        <th className="text-center">Categoría de Datos Personales</th>
                                        <th className="text-center">Ejemplos de Datos Personales que Recopilamos</th>
                                        <th className="text-center">Categorías de Terceros Con los Cuales<br />
                                            Compartimos estos
                                            Datos Personales
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">Perfil o Datos de Contacto</td>
                                        <td>
                                            <ul>
                                                <li>Nombre y apellido</li>
                                                <li>Correo electrónico</li>
                                                <li>Número de teléfono</li>
                                                <li>Dirección</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Proveedores de Servicios</li>
                                                <li>Socios Comerciales</li>
                                                <li>Terceros a los que Usted Autoriza, Accede o Autentifica</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">Dispositivo/Datos IP</td>
                                        <td>
                                            <ul>
                                                <li>Dirección IP</li>
                                                <li>Tipo/versión de navegador</li>
                                                <li>Dispositivos Únicos de Identificación</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Proveedores de Servicios</li>
                                                <li>Socios Comerciales</li>
                                                <li>Terceros a los que Usted Autoriza, Accede o Autentifica</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">Analítica Web</td>
                                        <td>
                                            <ul>
                                                <li>Interacciones en la página web</li>
                                                <li>Página web/fuente de referencia a través de la cual accedió
                                                    a los Servicios
                                                </li>
                                                <li>Solicitudes de identificación no identificables</li>
                                                <li>Estadísticas asociadas a la interacción entre el
                                                    dispositivo o navegador y los Servicios
                                                </li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Proveedores de Servicios</li>
                                                <li>Socios Comerciales</li>
                                                <li>Terceros a los que Usted Autoriza, Accede o Autentifica</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">Otra información para la identificación que usted decida
                                            proporcionar voluntariamente
                                        </td>
                                        <td>
                                            <ul>
                                                <li>La información de identificación en los correos
                                                    electrónicos o cartas que nos envíe
                                                </li>
                                                <li>Cualquier otro dato (texto, imágenes, georeferencias, etc.)
                                                    subido a DropControl
                                                </li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Proveedores de Servicios</li>
                                                <li>Socios Comerciales</li>
                                                <li>Terceros a los que Usted Autoriza, Accede o Autentifica</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                            <span className="mini-boldie-text with-mt10">Fuentes de Datos Personales</span>

                            <p>Recopilamos sus Datos Personales de las siguientes categorías de
                                fuentes:
                            </p>
                            <ul>
                                <li>De ti
                                    <ul>
                                        <li>Cuando nos proporcionas esa información directamente.
                                            <ul>
                                                <li>Cuando usas nuestro Servicio</li>
                                                <li>Cuando nos proporcionas voluntariamente información en
                                                    cuadros de texto de formato libre a través del sitio web o
                                                    mediante respuestas a encuestas o cuestionarios.
                                                </li>
                                                <li>Cuando nos envías un correo electrónico o te pones en
                                                    contacto con nosotros de alguna otra manera.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Cuando utilizas el Servicio y dicha información se recopila
                                            automáticamente.
                                        <ul>
                                            <li>A través de las Cookies (definidas en la sección
                                                    "Herramientas de Seguimiento y Opt-Out" más abajo).
                                            </li>
                                        </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>Terceros
                                    <ul>
                                        <li>Proveedores
                                            <ul>
                                                <li>Podemos utilizar proveedores de análisis para analizar
                                                    cómo interactúas y te relacionas con el Servicio, o terceros
                                                    pueden ayudarnos a proporcionar soporte.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <br />
                            <br />
                            <span className="mini-boldie-text">Nuestros propósitos comerciales o de negocios al recopilar
                                Datos Personales
                            </span>
                            <br />
                            <ul>
                                <li>Proporcionar, personalizar y mejorar el servicio, lo que
                                    incluye
                                <ul>
                                    <li>Proporcionarte los productos, servicios o información que
                                            solicite.
                                    </li>
                                    <li>Cumplir con la razón por la que nos proporcionaste la
                                            información.
                                    </li>
                                    <li>Proporcionar apoyo y asistencia para el Servicio.</li>
                                    <li>Mejorar el Servicio, incluyendo pruebas, investigación,
                                            análisis internos y desarrollo de productos.
                                    </li>
                                    <li>Personalizar el Servicio, el contenido del sitio web y las
                                            comunicaciones en función de tus preferencias.
                                    </li>
                                    <li>Proporcionar protección contra el fraude, seguridad y
                                            depuración.
                                    </li>
                                    <li>Llevar a cabo otros propósitos de negocios declarados al
                                            recopilar sus Datos Personales o como se establece de otra manera en
                                            las leyes de privacidad de datos aplicables, como la Ley de
                                            Privacidad del Consumidor de California (la "CCPA", por su sigla en
                                            inglés).
                                    </li>
                                </ul>
                                </li>
                                <li>Mantener contacto contigo, lo que incluye
                                    <ul>
                                        <li>Responder a tus mensajes, poniéndonos en contacto contigo
                                            cuando sea necesario o solicitado y enviándote información sobre
                                            nosotros o el sitio web. o Enviar correos electrónicos y otras
                                            comunicaciones de acuerdo con tus preferencias o que muestren
                                            contenidos que pensamos que serán de tu interés.
                                        </li>
                                    </ul>
                                </li>
                                <li>Cumplir con nuestras obligaciones legales y contractuales, lo
                                    que incluye
                                <ul>
                                    <li>Cumplir con nuestras obligaciones legales en virtud de las
                                            leyes, reglamentos, órdenes judiciales u otros procedimientos
                                            legales aplicables, como la prevención, detección e investigación de
                                            incidentes de seguridad y actividades potencialmente ilegales o
                                            prohibidas.
                                    </li>
                                    <li>Proteger los derechos, la propiedad o la seguridad de
                                            nuestros usuarios, nosotros o de terceros.
                                    </li>
                                    <li>Hacer cumplir cualquier los contratos que tengamos contigo.</li>
                                    <li>Responder a reclamos de que cualquier publicación u otro
                                            contenido viola los derechos de terceros.
                                    </li>
                                    <li>Resolver disputas.</li>
                                </ul>
                                </li>
                            </ul>
                            <p>No recopilaremos categorías adicionales de Datos Personales ni
                                utilizaremos los Datos Personales que hemos recopilado para fines
                                materialmente diferentes, no relacionados o incompatibles sin
                                notificárte o, si así lo exige la legislación aplicable, sin requerir
                                tu consentimiento.
                            </p>
                            <span className="sub-title">Herramientas de Seguimiento y Opt-Out</span>
                            <p>Utilizamos cookies y tecnologías de seguimiento similares para
                                rastrear la actividad en nuestro Servicio y mantener cierta
                                información. Las cookies son archivos con una pequeña cantidad de datos
                                que pueden incluir un identificador único anónimo. Las cookies se
                                envían a tu navegador desde un sitio web y se almacenan en tu
                                dispositivo. Las tecnologías de seguimiento que también se utilizan son
                                balizas, etiquetas y secuencias de comandos para recopilar y rastrear
                                información y para mejorar y analizar nuestro Servicio. Tú puedes
                                indicar en tu navegador que rechace todas las cookies o que indique
                                cuando se envía una cookie. Sin embargo, si no aceptas las cookies, es
                                posible que no pueda utilizar nuestro Servicio. Nosotros utilizamos los
                                siguientes tipos de Cookies:
                            </p>
                            <ul>
                                <li>Cookies de sesión. Utilizamos Cookies de Sesión para operar
                                    nuestro Servicio, se quedan en tu dispositivo hasta el final de la
                                    sesión.
                                </li>
                                <li>Cookies de Preferencias. Utilizamos las Cookies de
                                    Preferencias para recordar tus preferencias y varios ajustes,
                                    permanecen en tu dispositivo entre una semana y un mes.
                                </li>
                                <li>Cookies de Seguridad. Utilizamos Cookies de Seguridad por
                                    razones de seguridad, se mantienen en tu dispositivo hasta el final de
                                    la sesión.
                                </li>
                                <li>Cookies de Terceros.</li>
                            </ul>
                            <p>En algunos casos especiales también utilizamos cookies
                                proporcionadas por terceros de confianza. La siguiente sección detalla
                                las cookies de terceros que puede encontrar en este sitio.
                            </p>
                            <p>Usted puede verlas aquí:</p>
                            <ul>
                                <li>Google Analytics es un servicio web analítico ofrecido por
                                    Google que rastrea y reporta el tráfico del sitio web. Google utiliza
                                    los datos recopilados para rastrear y monitorear el uso de nuestro
                                    Servicio. Estos datos se comparten con otros Servicios de Google.
                                    Google puede utilizar los datos recopilados para contextualizar y
                                    personalizar los anuncios de su propia red publicitaria. Usted puede
                                    optar por no poner su actividad en el Servicio a disposición de Google
                                    Analytics instalando el complemento Opt-out (de exclusión) de Google
                                    Analytics para el navegador. El complemento impide que el JavaScript
                                    de Google Analytics (ga.js, analytics.js y dc.js) comparta información
                                    con Google Analytics sobre la actividad de las visitas. Para obtener
                                    más información sobre las prácticas de privacidad de Google, por favor
                                    visita la página web sobre Privacidad y Condiciones de Google: <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=en</a>
                                </li>
                                <li>Froged - Para obtener más información sobre las prácticas de privacidad de Froged, por favor
                                    visita su página web sobre Privacidad: <a href="https://froged.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://froged.com/privacy-policy</a>
                                </li>
                                <li>Google Chrome Para obtener más información sobre las prácticas
                                    de privacidad de Google, por favor visita la página web sobre
                                    Privacidad y Condiciones de Google: <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/95647?hl=en</a>
                                </li>
                                <li>Internet Explorer Para obtener más información acerca de las
                                    prácticas de privacidad de Microsoft, por favor visita la página web
                                    sobre Privacidad y Condiciones de Microsoft: <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                                </li>
                                <li>Safari Para obtener más información acerca de las prácticas de
                                    privacidad de Safari, por favor visita la página web sobre Privacidad
                                    y Condiciones de Safari: <a href="https://support.apple.com/kb/PH19214?locale=es_ES&amp;viewlocale=en_US" target="_blank" rel="noopener noreferrer">https://support.apple.com/kb/PH19214?locale=es_ES&amp;viewlocale=en_US</a>
                                </li>
                                <li>Firefox Para obtener más información acerca de las prácticas
                                    de privacidad de Firefox, por favor visita la página web sobre
                                    Privacidad y Condiciones de Firefox: <a href="https://support.apple.com/kb/PH19214?locale=es_ES&amp;viewlocale=en_US" target="_blank" rel="noopener noreferrer">https://support.apple.com/kb/PH19214?locale=es_ES&amp;viewlocale=en_US</a>
                                </li>
                            </ul>
                            <span className="sub-title">Seguridad de Datos y Retención</span>
                            <p>La seguridad de tus datos es importante para nosotros, pero
                                recuerde que ningún método de transmisión por Internet, o método de
                                almacenamiento electrónico es 100% seguro. Los datos que almacenamos,
                                los protegeremos dentro de los medios comercialmente aceptables para
                                evitar la pérdida y el robo, así como el acceso, la divulgación, la
                                copia, el uso o la modificación no autorizados, pero no podemos
                                garantizar su seguridad absoluta.
                            </p>
                            <p>Conservamos tus Datos Personales durante todo el tiempo que
                                tengas una cuenta vigente con nosotros o cuando sea necesario para
                                proporcionarte nuestros Servicios. En algunos casos, conservamos los
                                Datos Personales durante más tiempo, si hacerlo es necesario para
                                cumplir con nuestras obligaciones legales, resolver disputas o cobrar
                                honorarios adeudados, o si es permitido o requerido por la ley o
                                regulación aplicable. Además, podemos retener la información de forma
                                anónima o agregada cuando dicha información no te identifique
                                personalmente.
                            </p>
                            <span className="sub-title">Privacidad de los Niños</span>
                            <p>Nuestro servicio no se dirige a nadie menor de 18 años ("Niños").
                                No recogemos conscientemente Datos Personales de nadie menor de 18
                                años. Si usted es un padre o tutor y sabe que sus hijos nos han
                                proporcionado Datos Personales, por favor, póngase en contacto con
                                nosotros. Si nos damos cuenta de que hemos recopilado Datos Personales
                                de niños sin la verificación del consentimiento de los padres, tomamos
                                medidas para eliminar esa información de nuestros servidores.
                            </p>
                            <span className="sub-title">Derechos del Titular de los Datos</span>
                            <p>Dependiendo de tu jurisdicción, usted puede tener los siguientes
                                derechos con respecto a sus Datos Personales:
                            </p>
                            <ul>
                                <li>Derecho de acceso</li>
                                <li>Derecho a rectificación</li>
                                <li>Derecho de cancelación o supresión</li>
                                <li>Derecho a oponerse a cierto procesamiento de tus Datos
                                    Personales
                                </li>
                            </ul>
                            <p>Por favor, consulte la sección "Ejercicio de sus Derechos" a
                                continuación para obtener instrucciones sobre cómo ejercer estos
                                derechos. Tenga en cuenta que podemos procesar datos personales de los
                                usuarios finales o empleados de nuestros clientes en relación con la
                                prestación de determinados servicios a nuestros clientes. Si estamos
                                procesando tus Datos Personales como proveedor de Servicios, deberá
                                ponerse en contacto con la entidad que recogió tus Datos Personales en
                                primera instancia para tratar sus derechos con respecto a dichos datos.
                            </p>
                            <p>
                                Si existe algún conflicto entre esta sección y cualquier otra
                                disposición de esta Política de Privacidad , la parte que sea más
                                protectora de Datos Personales controlará la extensión de dicho
                                conflicto. Si tiene alguna pregunta sobre esta sección o si alguno de
                                los siguientes derechos se aplica a usted, por favor contáctenos en <a href="mailto:soporte@wiseconn.cl" target="_blank" rel="noopener noreferrer">soporte@wiseconn.cl</a>.
                            </p>
                            <span className="mini-boldie-text">Acceso</span>
                            <p>Tienes derecho a solicitar cierta información sobre nuestra
                                recopilación y uso de tus Datos Personales. En respuesta, te
                                proporcionaremos la siguiente información:
                            </p>
                            <ul>
                                <li>Las categorías de Datos Personales que hemos recopilado sobre
                                    ti.
                                </li>
                                <li>Las categorías de fuentes de las que se recogieron esos Datos
                                    Personales.
                                </li>
                                <li>El propósito comercial o de negocios para recopilar o
                                    transferir tus Datos Personales.
                                </li>
                                <li>Las categorías de terceros con los que hemos compartido tus
                                    Datos Personales.
                                </li>
                                <li>Las Datos Personales específicos que hemos recopilado sobre
                                    tí.
                                </li>
                            </ul>
                            <p>Si hemos revelado tus Datos Personales a terceros con fines
                                comerciales en los últimos 12 meses, identificaremos las categorías de
                                Datos Personales compartidas con cada categoría de terceros
                                destinatarios. Si hemos transferido sus Datos Personales en los últimos
                                12 meses, identificaremos las categorías de Datos Personales vendidos a
                                cada categoría de terceros destinatarios.
                            </p>
                            <span className="mini-boldie-text">Rectificación</span>
                            <p>Tienes derecho a solicitar la modificación de tus Datos
                                Personales, en caso de que sean erróneos, imprecisos, engañosos o
                                incompletos.
                            </p>
                            <span className="mini-boldie-text">Supresión</span>
                            <p>Tienes derecho a solicitar que eliminemos los Datos Personales
                                que hemos recopilado sobre ti. Cuando proceda, en virtud de la ley CCPA
                                (si fuere aplicable), este derecho está sujeto a ciertas excepciones:
                                por ejemplo, es posible que necesitemos conservar tus Datos Personales
                                para proporcionarle los Servicios o completar una transacción u otra
                                acción que usted haya solicitado. Si tu solicitud de eliminación está
                                sujeta a una de estas excepciones, podemos denegar tu solicitud de
                                eliminación.
                            </p>
                            <span className="mini-boldie-text">Ejercicio de Sus Derechos</span>
                            <p>Para ejercer los derechos descritos anteriormente, usted o tu
                                Agente Autorizado (definido a continuación) debe enviarnos una
                                solicitud que (1) proporcione suficiente información que nos permita
                                verificar que tú eres la persona sobre la que hemos recopilado Datos
                                Personales, y que ( 2) describa tu solicitud con suficiente detalle
                                para permitirnos comprenderla, evaluarla y responder a ella. Cada
                                solicitud que cumpla con ambos criterios será considerada una
                                "Solicitud Válida". Podríamos requerir información adicional si tu
                                solicitud no cumple con estos criterios. Sólo utilizaremos los Datos
                                Personales proporcionados en una Solicitud Válida para verificar tu
                                identidad y completar tu solicitud. No necesita una cuenta para
                                presentar una Solicitud Válida.
                            </p>
                            <p>Trabajaremos para responder a tu Solicitud Válida dentro de los
                                45 días de su recepción, o el término legal que corresponda. No le
                                cobraremos una tarifa por hacer una Solicitud Válida a menos que, si lo
                                permite la ley aplicable, su(s) Solicitud(es) Válida(s) sea(n)
                                excesiva(s), repetitiva(s) o manifiestamente infundada(s).
                            </p>
                            <p>Puedes presentar una Solicitud Válida utilizando los siguientes
                                métodos:
                            </p>
                            <ul>
                                <li>Llámanos a: <a href="tel:+56226565003">+56 (2) 2 6565 003</a></li>
                                <li>Mándanos un correo electrónico: <a href="mailto:soporte@wiseconn.cl" target="_blank" rel="noopener noreferrer">soporte@wiseconn.cl</a></li>
                            </ul>
                            <p>También puedes autorizar a un representante (un "Representante
                                Autorizado") a ejercer tus derechos en tu nombre. Para ello, debes
                                proporcionar a su Representante Autorizado un permiso escrito para
                                ejercer tus derechos en tu nombre, y podemos solicitar una copia de
                                este permiso escrito a tu Representante Autorizado cuando hagas una
                                solicitud en tu nombre.
                            </p>
                            <span className="sub-title">Opt-Out y Opt-In de Venta de Datos Personales</span>
                            <p>Nosotros no venderemos tus Datos Personales.</p>
                            <span className="sub-title">Cambios a esta Política de Privacidad</span>
                            <p>Ocasionalmente, podemos actualizar nuestra Política de
                                Privacidad. Te notificaremos de cualquier cambio publicando la nueva
                                Política de Privacidad en esta página. Te informaremos por correo
                                electrónico y/o mediante un aviso destacado en nuestro Servicio, antes
                                de que el cambio entre en vigencia y actualizaremos la "fecha de
                                vigencia" al final de esta Política de Privacidad. Se te aconseja
                                revisar esta Política de Privacidad periódicamente por si hubiera algún
                                cambio. Los cambios a esta Política de Privacidad son efectivos cuando
                                se publican en esta página. Cuando lo requieran las leyes aplicables,
                                requeriremos tu expreso consentimiento para algunos cambios.
                            </p>
                            <p>
                                Te hacemos presente que la versión oficial de esta Política de
                                Privacidad será la versión en inglés que encuentras <a onClick={() => changeLang()} role="button"><u>acá</u></a> y esta
                                prevalecerá por sobre cualquier traducción. Esta versión en español
                                tiene solo por objetivo comunicarte claramente y entregarte información
                                de los términos bajo los cuáles trataremos tus Datos Personales.
                            </p>
                            <span className="sub-title">Contáctenos</span>
                            <p>Si tienes alguna pregunta sobre esta Política de Privacidad, por
                                favor contáctanos:
                            </p>
                            <p>
                                Por correo electrónico: <a href="mailto:soporte@wiseconn.cl" target="_blank" rel="noopener noreferrer">soporte@wiseconn.cl</a>
                            </p>
                            <p>
                                Visitando esta página en nuestro sitio web: <a href="https://www.wiseconn.cl" target="_blank" rel="noopener noreferrer">https://www.wiseconn.cl</a>
                            </p>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="footer-container" >
                            <div id="container-checks" style={{ lineHeight: 0 }}>
                                <div id="check1" className="d-inline-flex" style={{ height: 0 }}>
                                    <p className="d-inline-flex">
                                        <input className="form-control" type="checkbox"
                                            ref={checkRef1} style={{ height: "28px" }}
                                            onChange={() => {
                                                if (checkRef1.current.checked && checkRef2.current.checked) {
                                                    btnRef.current.className = "btn acceptCookBtn";
                                                } else {
                                                    btnRef.current.className = "btn acceptCookBtn disabled";
                                                }
                                            }}
                                        />
                                        <span>Acepto la política de privacidad de WiseConn</span>
                                    </p>
                                </div>
                                <br />
                                <div id="check2" className="d-inline-flex" style={{ height: 0 }}>
                                    <p className="d-inline-flex">
                                        <input className="form-control" type="checkbox"
                                            ref={checkRef2} style={{ height: "28px" }}
                                            onChange={() => {
                                                if (checkRef1.current.checked && checkRef2.current.checked) {
                                                    btnRef.current.className = "btn acceptCookBtn";
                                                } else {
                                                    btnRef.current.className = "btn acceptCookBtn disabled";
                                                }
                                            }}
                                        />
                                        <span>Acepto los <a href="https://www.dropcontrol.com/public/terms.jsp" target="_blank" rel="noopener noreferrer"> <u role="button">términos y condiciones</u></a> de WiseConn</span>
                                    </p>
                                </div>
                            </div>


                            <a className="btn acceptCookBtn disabled" ref={btnRef}
                                style={{ marginBottom: "20px", marginTop: "2px" }}
                                onClick={() => acceptCook(email, navigate, revalidator)}
                            >Accept</a>
                            <br />
                            <p className="text-footer">
                                Ingeniería Wiseconn SpA.
                                ·
                                Soporte : <strong><a href="tel:+56226565003">(+56 2) 26565003</a></strong>
                                ·
                                Carmencita 25, Oficina 52, Las Condes - Chile - <a href="tel:+56229078586"> (+56 2)  29078586 </a>
                                <a href="https://www.dropcontrol.com/public/privacy.jsp" target="_blank" rel="noopener noreferrer" className="footer-link">Políticas de Privacidad de WiseConn</a> · <a href="https://www.dropcontrol.com/public/terms.jsp" target="_blank" rel="noopener noreferrer" className="footer-link">Terminos y Condiciones</a>
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <div id="container-login">
                    <div id="container">
                        <div className='nav-wrapper'>
                            <nav>
                                <ul className='nav'>
                                    <li className='langSelector' style={{ cursor: "pointer" }}>
                                        <a href="#es_CL" onClick={changeLang}><img alt="change-language" src={mundo} />{t("login.lang")}</a>
                                    </li>
                                    <li>
                                        <a href="//www.wiseconn.com/contact" target="_blank" rel="noreferrer">{t("login.contacto")}</a>
                                    </li>
                                    <li>
                                        <a href="//www.wiseconn.com" target="_blank" rel="noreferrer">Wiseconn</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div className="terms-wrapper text">
                            <div>
                                <span className="title" >WiseConn's Privacy Policy</span>
                            </div>
                            <span className="mini-boldie-text">JANUARY, 2023</span>
                            <p>
                                Your privacy is important to us and WiseConn makes an effort to respect
                                your privacy regarding any information we may collect from you across
                                DropControl System or via our website (the “Service”). DropControl is a
                                system composed of a web platform, websites, mobile Apps, field devices
                                and API services, that allows to monitor and analyze, in real time,
                                vital information for agricultural management, and thus, make timely
                                decisions that allow the optimization of the resources of an agricultural
                                field. This page informs you of our policies regarding the collection, use,
                                and disclosure of personal data when you use our Service and the choices you
                                have associated with that data. Unless otherwise defined in this Privacy Policy,
                                terms used in this Privacy Policy have the same meanings as in our{" "}
                                <a href="https://www.dropcontrol.com/public/terms.jsp" target="_blank" rel="noopener noreferrer" role="button">Terms and Conditions</a>.
                                If you have any questions about how we handle user data and personal
                                information, feel free to contact us at <a href="mailto:soporte@wiseconn.cl" rel="noopener noreferrer" target="_blank">support@wiseconn.com</a>.
                            </p>
                            <span className="sub-title" >What this Privacy Policy Covers</span>
                            <p>
                                This Privacy Policy covers important information and disclosures relating
                                to how we treat personal information that we gather when you access
                                or use our website. WiseConn may contain links to other websites of
                                interest. However, once you have used these links to leave our site,
                                you should note that we do not have any control over that other website.
                                Therefore, we cannot be responsible for the protection and privacy of
                                any information that you provide while visiting such sites since they
                                are not governed by this privacy statement. You should exercise caution
                                and look at the privacy statement applicable to the website in question.
                                Please note that you may have additional rights to control the use and disclosure
                                of your personal information. Residents of California should refer to the
                                relevant subsections below. You may print a copy of this Privacy Policy by
                                clicking <a href="javascript:void(0)" onClick={createPrintFn("terms-container")}>here</a>. If you have a disability, you may access this Privacy Policy
                                in an alternative format by contacting <a href="mailto:soporte@wiseconn.cl" target="_blank" rel="noopener noreferrer">support@wiseconn.com</a>.
                            </p>
                            <span className="sub-title">Information Collection And Use</span>
                            <p>We collect several different types of information for various
                                purposes to provide and improve our Service to you.
                                Use of the term “Personal Information” herein means
                                any information that identifies or relates to a particular
                                individual and also includes information referred to as
                                “personally identifiable information” or “personal information”
                                under applicable data privacy laws, rules or regulations.
                                The chart below details the categories of personal information
                                that we collect and how we use the data.
                            </p>

                            <table className="terms-categories">
                                <tbody>
                                    <tr>
                                        <th className="text-center">Category of Personal Information</th>
                                        <th className="text-center">Examples of Personal Information We Collect</th>
                                        <th className="text-center">Categories of Third Parties With<br />
                                            Whom We Share this Personal Information
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">Profile or Contact Data</td>
                                        <td>
                                            <ul>
                                                <li>First and last name</li>
                                                <li>Email</li>
                                                <li>Phone number</li>
                                                <li>Address</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Service Providers</li>
                                                <li>Business Partners</li>
                                                <li>Parties You Authorize, Access or Authenticate</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">Device/IP Data</td>
                                        <td>
                                            <ul>
                                                <li>IP address</li>
                                                <li>Browser type/version</li>
                                                <li>Unique device identifiers</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Service Providers</li>
                                                <li>Business Partners</li>
                                                <li>Parties You Authorize, Access or Authenticate</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">Web Analytics</td>
                                        <td>
                                            <ul>
                                                <li>Web page interactions</li>
                                                <li>Referring webpage/source through which you accessed the Services</li>
                                                <li>Non-identifiable request IDs</li>
                                                <li>Statistics associated with the interaction between device or browser and the Services</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Service Providers</li>
                                                <li>Business Partners</li>
                                                <li>Parties You Authorize, Access or Authenticate</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">Other Identifying Information that You Voluntarily Choose to Provide</td>
                                        <td>
                                            <ul>
                                                <li>Identifying information in emails or letters you send us</li>
                                                <li>Any other data item (text, images, georeferences, etc.) uploaded to DropControl)</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Service Providers</li>
                                                <li>Business Partners</li>
                                                <li>Parties You Authorize, Access or Authenticate</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                            <span className="mini-boldie-text with-mt10">Sources of Personal Information</span>

                            <p>We collect Personal Information about you from the following categories of sources:</p>
                            <ul>
                                <li>You
                                    <ul>
                                        <li>When you provide such information directly to us.
                                            <ul>
                                                <li>When you use our Service</li>
                                                <li>When you voluntarily provide information in free-form text
                                                    boxes through the website or through responses to surveys or questionnaires.
                                                </li>
                                                <li>When you send us an email or otherwise contact us.</li>
                                            </ul>
                                        </li>
                                        <li>When you use the Service and such information is collected automatically.
                                            <ul>
                                                <li>Through Cookies (defined in the “Tracking Tools and Opt-Out” section below).</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>Third Parties
                                    <ul>
                                        <li>Vendors
                                            <ul>
                                                <li>We may use analytics providers to analyze how you interact and engage with the Service,
                                                    or third parties may help us provide you with customer support.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <br />
                            <br />
                            <span className="mini-boldie-text">Our Commercial or Business Purposes for Collecting Personal Information</span>
                            <br />
                            <ul>
                                <li>Providing, Customizing and Improving the Service, including
                                    <ul>
                                        <li>Providing you with the products, services or information you
                                            request.
                                        </li>
                                        <li>Meeting or fulfilling the reason you provided the
                                            information to us.
                                        </li>
                                        <li>Providing support and assistance for the Service.</li>
                                        <li>Improving the Service, including testing, research, internal
                                            analytics and product development.
                                        </li>
                                        <li>Personalizing the Service, website content and
                                            communications based on your preferences.
                                        </li>
                                        <li>Doing fraud protection, security and debugging.</li>
                                        <li>o Carrying out other business purposes stated when
                                            collecting your Personal Information or as otherwise set forth in
                                            applicable data privacy laws, such as the California Consumer
                                            Privacy Act (the “CCPA”).
                                        </li>
                                    </ul>
                                </li>
                                <li>Corresponding with You, including
                                    <ul>
                                        <li>Responding to correspondence that we receive from you,
                                            contacting you when necessary or requested, and sending you
                                            information about us or the website.
                                        </li>
                                        <li>Sending emails and other communications according to your
                                            preferences or that display content that we think will interest you.
                                        </li>
                                    </ul>
                                </li>
                                <li>Meeting Legal Requirements and Enforcing Legal Terms, including
                                    <ul>
                                        <li>Fulfilling our legal obligations under applicable law,
                                            regulation, court order or other legal process, such as preventing,
                                            detecting and investigating security incidents and potentially
                                            illegal or prohibited activities.
                                        </li>
                                        <li>Protecting the rights, property or safety of you, us or
                                            another party.
                                        </li>
                                        <li>Enforcing any agreements with you.</li>
                                        <li>Responding to claims that any posting or other content
                                            violates third-party rights.
                                        </li>
                                        <li>Resolving disputes.</li>
                                    </ul>
                                </li>
                            </ul>
                            <p>We will not collect additional categories of Personal Information or
                                use the Personal Information we collected for materially different,
                                unrelated or incompatible purposes without providing you notice or,
                                if required by applicable law, requiring your consent.
                            </p>

                            <span className="sub-title">Tracking Tools and Opt-Out</span>
                            <p>We use cookies and similar tracking technologies to track the activity
                                on our Service and hold certain information. Cookies are files with small
                                amount of data which may include an anonymous unique identifier.
                                Cookies are sent to your browser from a website and stored on your device.
                                Tracking technologies also used are beacons, tags, and scripts to collect
                                and track information and to improve and analyze our Service.
                                You can instruct your browser to refuse all cookies or to indicate
                                when a cookie is being sent. However, if you do not accept cookies,
                                you may not be able to use our Service. We use the following types of Cookies:
                            </p>
                            <ul>
                                <li>Session Cookies. We use Session Cookies to operate our
                                    Service, they stay in your device until the end of the session.
                                </li>
                                <li>Preference Cookies. We use Preference Cookies to remember your
                                    preferences and various settings, they stay in your device between a
                                    week and a month.
                                </li>
                                <li>Security Cookies. We use Security Cookies for security
                                    purposes, they stay in your device until the end of the session.
                                </li>
                                <li>Third Party Cookies.</li>
                            </ul>
                            <p>In some special cases we also use cookies provided by trusted third parties.
                                The following section details which third party cookies you might encounter through this site.
                            </p>
                            <p>You can view them here:</p>
                            <ul>
                                <li>Google Analytics is a web analytics service offered by Google
                                    that tracks and reports website traffic. Google uses the data
                                    collected to track and monitor the use of our Service. This data is
                                    shared with other Google services. Google may use the collected data
                                    to contextualize and personalize the ads of its own advertising
                                    network. You can opt-out of having made your activity on the Service
                                    available to Google Analytics by installing the Google Analytics
                                    opt-out browser add-on. The add-on prevents the Google Analytics
                                    JavaScript (ga.js, analytics.js, and dc.js) from sharing information
                                    with Google Analytics about visits activity. For more information on
                                    the privacy practices of Google, please visit the Google Privacy &amp;
                                    Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
                                </li>
                                <li>Froged - For more information on the privacy practices of Froged, please
                                    visit the Froged Privacy Policy web page: <a href="https://froged.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://froged.com/privacy-policy</a>
                                </li>
                                <li>Google Chrome For more information on the privacy practices of
                                    Google, please visit the Google Privacy &amp; Terms web page: <a href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a>
                                </li>
                                <li>Internet Explorer For more information on the privacy
                                    practices of Microsoft, please visit the Microsoft Privacy &amp; Terms web
                                    page: <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                                </li>
                                <li>Safari For more information on the privacy practices of
                                    Safari, please visit the Safari Privacy &amp; Terms web page: <a href="https://support.apple.com/kb/PH19214?locale=es_ES&amp;viewlocale=en_US">https://support.apple.com/kb/PH19214?locale=es_ES&amp;viewlocale=en_US</a>
                                </li>
                                <li>Firefox For more information on the privacy practices of
                                    Firefox, please visit the Firefox Privacy &amp; Terms web page: <a href="https://support.apple.com/kb/PH19214?locale=es_ES&amp;viewlocale=en_US">https://support.apple.com/kb/PH19214?locale=es_ES&amp;viewlocale=en_US</a>
                                </li>
                            </ul>
                            <span className="sub-title">Security of Data and Retention</span>
                            <p>The security of your data is important to us, but remember that
                                no method of transmission over the Internet, or method of electronic
                                storage is 100% secure. What data we store, we’ll protect within
                                commercially acceptable means to prevent loss and theft,
                                as well as unauthorized access, disclosure, copying, use or modification,
                                but we cannot guarantee its absolute security.
                            </p>
                            <p>We retain Personal Information about you for as long as you have an open
                                account with us or as otherwise necessary to provide you with our Services.
                                In some cases, we retain Personal Information for longer,
                                if doing so is necessary to comply with our legal obligations,
                                resolve disputes or collect fees owed, or is otherwise permitted
                                or required by applicable law, rule or regulation. We may further retain
                                information in an anonymous or aggregated form where that information
                                would not identify you personally.
                            </p>
                            <span className="sub-title">Children’s Privacy</span>
                            <p>Our Service does not address anyone under the age of 18 (“Children”).
                                We do not knowingly collect personally identifiable information from
                                anyone under the age of 18. If you are a parent or guardian and you
                                are aware that your Children has provided us with Personal information,
                                please contact us. If we become aware that we have collected Personal Information
                                from children without verification of parental consent,
                                we take steps to remove that information from our servers.
                            </p>
                            <span className="sub-title">Data Subjects’ Rights</span>
                            <p>Depending on your jurisdiction, you may have the following rights regarding your Personal Information:</p>
                            <ul>
                                <li>Right of access</li>
                                <li>Right of rectification</li>
                                <li>Right of cancellation or deletion</li>
                                <li>Right to oppose certain processing of your Personal
                                    Information.
                                </li>
                            </ul>
                            <p>Please see the “Exercising Your Rights” section below for instructions
                                regarding how to exercise these rights. Please note that we may process
                                Personal Information of our customers’ end users or employees in connection
                                with our provision of certain services to our customers.
                                If we are processing your Personal Information as a service provider,
                                you should contact the entity that collected your Personal Information
                                in the first instance to address your rights with respect to such data.
                            </p>
                            <p>
                                If there are any conflicts between this section and any other provision
                                of this Privacy Policy , the portion that is more protective of Personal
                                Information shall control to the extent of such conflict. If you have
                                any questions about this section or whether any of the following
                                rights apply to you, please contact us at <a href="mailto:soporte@wiseconn.cl" target="_blank" rel="noopener noreferrer">support@wiseconn.com</a>.
                            </p>
                            <span className="mini-boldie-text">Access</span>
                            <p>You have the right to request certain information about our
                                collection and use of your Personal Information.
                                In response, we will provide you with the following information:
                            </p>
                            <ul>
                                <li>The categories of Personal Information that we have collected
                                    about you.
                                </li>
                                <li>The categories of sources from which that Personal Information
                                    was collected.
                                </li>
                                <li>The business or commercial purpose for collecting or selling
                                    your Personal, Information.
                                </li>
                                <li>The categories of third parties with whom we have shared your
                                    Personal Information.
                                </li>
                                <li>The specific pieces of Personal Information that we have
                                    collected about you.
                                </li>
                            </ul>
                            <p>If we have disclosed your Personal Information to any third parties
                                for a business purpose over the past 12 months, we will identify
                                the categories of Personal Information shared with each category
                                of third party recipient. If we have transfer your Personal Information
                                over the past 12 months, we will identify the categories of Personal Information
                                sold to each category of third party recipient.
                            </p>
                            <span className="mini-boldie-text">Rectification</span>
                            <p>You have the right to request the modification of your Personal Information, in case it is wrong, imprecise, misleading or incomplete.</p>
                            <span className="mini-boldie-text">Deletion</span>
                            <p>You have the right to request that we delete the Personal Information
                                that we have collected about you. In applicable, under the CCPA,
                                this right is subject to certain exceptions: for example,
                                we may need to retain your Personal Information to provide you
                                with the Services or complete a transaction or other action
                                you have requested. If your deletion request is subject to
                                one of these exceptions, we may deny your deletion request.
                            </p>
                            <span className="mini-boldie-text">Exercising Your Rights</span>
                            <p>To exercise the rights described above, you or your Authorized
                                Agent (defined below) must send us a request that (1) provides
                                sufficient information to allow us to verify that you are the
                                person about whom we have collected Personal Information, and
                                (2) describes your request in sufficient detail to allow us to
                                understand, evaluate and respond to it. Each request that meets
                                both of these criteria will be considered a “Valid Request.” We
                                may require additional information if your request does not meet
                                these criteria. We will only use Personal Information provided in
                                a Valid Request to verify your identity and complete your request.
                                You do not need an account to submit a Valid Request.
                            </p>
                            <p>We will work to respond to your Valid Request within 45 days of receipt,
                                or the applicable legal term. We will not charge you a fee for
                                making a Valid Request unless, if permitted by applicable law,
                                your Valid Request(s) is excessive, repetitive or manifestly
                                unfounded.
                                You may submit a Valid Request using the following methods:
                            </p>
                            <ul>
                                <li>Call us at: <a href="tel:+1553440800">+1 (559) 344 0800</a></li>
                                <li>Email us at: <a href="mailto:support@wiseconn.com" target="_blank" rel="noopener noreferrer">support@wiseconn.com</a></li>
                                <li>Submit a form at this address: <a href="https://www.wiseconn.com/contact" target="_blank" rel="noopener noreferrer">https://www.wiseconn.com/contact</a></li>
                            </ul>
                            <p>You may also authorize an agent (an “Authorized Agent”) to exercise
                                your rights on your behalf. To do this, you must provide your
                                Authorized Agent with written permission to exercise your rights
                                on your behalf, and we may request a copy of this written
                                permission from your Authorized Agent when they make a request
                                on your behalf.
                            </p>
                            <span className="sub-title">Personal Information Sales Opt-Out and Opt-In</span>
                            <p>We will not sell your Personal Information.</p>
                            <span className="sub-title">Changes To This Privacy Policy</span>
                            <p>We may update our Privacy Policy from time to time. We will notify
                                you of any changes by posting the new Privacy Policy on this page.
                                We will let you know via email and/or a prominent notice on our
                                Service, prior to the change becoming effective and update the
                                “effective date” at the bottom of this Privacy Policy. You are
                                advised to review this Privacy Policy periodically for any changes.
                                Changes to this Privacy Policy are effective when they are posted
                                on this page. When required by applicable laws, we will require
                                your express consent to some changes.
                            </p>

                            <span className="sub-title">Contact Us</span>
                            <p>If you have any questions about this Privacy Policy, please contact us:</p>
                            <p>
                                By email: <a href="mailto:support@wiseconn.com">support@wiseconn.com</a>
                            </p>
                            <p>
                                By visiting this page on our website: <a href="https://www.wiseconn.com/contact" target="_blank" rel="noopener noreferrer">https://www.wiseconn.com/contact</a>
                            </p>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="footer-container" >
                            <div id="container-checks" style={{ lineHeight: 0 }}>
                                <div id="check1" className="d-inline-flex" style={{ height: 0 }}>
                                    <p className="d-inline-flex">
                                        <input className="form-control" type="checkbox"
                                            ref={checkRef1} style={{ height: "28px" }}
                                            onChange={() => {
                                                if (checkRef1.current.checked && checkRef2.current.checked) {
                                                    btnRef.current.className = "btn acceptCookBtn";
                                                } else {
                                                    btnRef.current.className = "btn acceptCookBtn disabled";
                                                }
                                            }}
                                        />
                                        <span>I accept Wiseconn Privacy Policy</span>
                                    </p>
                                </div>
                                <br />
                                <div id="check2" className="d-inline-flex" style={{ height: 0 }}>
                                    <p className="d-inline-flex">
                                        <input className="form-control" type="checkbox"
                                            ref={checkRef2} style={{ height: "28px" }}
                                            onChange={() => {
                                                if (checkRef1.current.checked && checkRef2.current.checked) {
                                                    btnRef.current.className = "btn acceptCookBtn";
                                                } else {
                                                    btnRef.current.className = "btn acceptCookBtn disabled";
                                                }
                                            }}
                                        />
                                        <span>I accept Wiseconn<a href="https://www.dropcontrol.com/public/terms.jsp" target="_blank" rel="noopener noreferrer"> <u>Term and Conditions</u></a></span>
                                    </p>
                                </div>
                            </div>

                            <a className="btn acceptCookBtn disabled" ref={btnRef}
                                style={{ marginBottom: "20px", marginTop: "2px" }}
                                onClick={() => acceptCook(email, navigate, revalidator)}
                            >Accept</a>
                            <br />
                            <p className="text-footer">
                                WiseConn Engineering Inc.
                                ·
                                Support : <strong><a href="tel:+15593440800">+1 (559) 344-0800</a></strong><br /><strong><a href="tel:+15592724600">+1 (559) 272-4600</a></strong>
                                ·
                                5252 E Home Ave, Fresno, CA 93722 - USA - <a href="tel:+15593267613">+1 (559) 326-7613</a>
                                <a href="https://www.dropcontrol.com/public/privacy.jsp" target="_blank" rel="noopener noreferrer" className="footer-link">WiseConn's Privacy Policy</a> · <a href="https://www.dropcontrol.com/public/terms.jsp" target="_blank" rel="noopener noreferrer" className="footer-link">Terms and Conditions</a>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PolicyAndTerms;
