import { Component } from "react";
import { sendError, ShowError } from "./ShowError";

import { v1 as uuidv1 } from "uuid";
import { getCache } from "../../utils/logcache";

class AWSErrorBoundary extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = { error: null, uuid: null };
    }

    componentDidMount() { this._isMounted = true; }
    componentWillUnmount() { this._isMounted = false; }
    clearState() {
        if (this && this._isMounted) this.setState({ error: null });
    }

    static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
        const uuid = uuidv1();
        const time = new Date();
        error.cache = getCache();
        if (error) error.uuid = uuid;
        return { error: error && error.toString(), uuid, time };
    }

    componentDidCatch(error, errorInfo) {
    // Log error messages to an error reporting service
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
        sendError(error, this.props.name, error.uuid, error.cache, errorInfo.componentStack);
    }

    render() {
        if (this.state.error) {
            return <ShowError resetOnLocationChange={this.props.resetOnChange} clearState={this.clearState.bind(this)} uuid={this.state.uuid} time={this.state.time} />;
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default AWSErrorBoundary;
