// check wiseconn-env-gen.js for build envs
const region = import.meta.env.VITE_REGION ?? "us-east-1";
const endpoint = import.meta.env.VITE_ENDPOINT ?? `https://qkud66oww6.execute-api.${region}.amazonaws.com/dev`;
const domain = import.meta.env.VITE_DOMAIN ?? (
    import.meta.env.DEV ? "http://localhost:3000" : "https://admin.dropcontrol.com"
);

/**
 @type {import("@aws-amplify/core").ResourcesConfig}
 */
const wise_exports =  {
    Auth: {
        Cognito: {
            userPoolId:`${region}_Izgq043do`,
            userPoolClientId: "459brd05li2akb10d8g66frs50",
            region: region,
            authenticationFlowType: "USER_SRP_AUTH",
            loginWith: {
                oauth: {
                    domain: "auth.wiseconn.com",
                    redirectSignIn: [domain],
                    redirectSignOut: [domain],
                    responseType: "code",
                    scopes: [
                    //     "email",
                    //     "profile",
                    //     "openid",
                    //     "aws.cognito.signin.user.admin"
                    ]
                }
            }

        }
    },
    API: {
        REST: {
            "apiAdminDropControl": {
                endpoint: endpoint,
                region: region
            },
            "apiAdminDropControlNoAuth": {
                endpoint: endpoint,
                region: region
            }
        }
    }
};

export default wise_exports;
