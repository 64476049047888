import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { RestAPI } from "@/utils/api-rest";

const sendError = async (error, boundaryName, uuid, errorCache, componentErrorStack) => {
    const his = globalThis.history;
    const loc = globalThis.location;
    const { expanded, updateTree } = his.state && his.state.state || {};
    const state = JSON.stringify({ expanded, updateTree }).substring(0, 2000);
    const url = loc.href;
    const stack = error.stack && error.stack.substring(0, 2000);
    const errorMsg = error.toString();
    const componentStack = componentErrorStack && componentErrorStack.substring(0, 2000);
    const cache = Array.isArray(errorCache) ? errorCache.sort((a, b) => {
        if (!a) return -1;
        if (!b) return 1;
        if (a.ts > b.ts) return 1;
        if (a.ts < b.ts) return -1;
        return 0;
    }) : undefined;
    const cacheString = cache && cache.map(a => JSON.stringify(a)).join("\n");

    const data = { uuid, errorMsg, url, state, boundaryName, componentStack, stack, cache: cacheString };
    await RestAPI.post("apiAdminDropControl", "/notify/error", { body: data }).catch(e => console.error(e));

};

const ShowError = ({ uuid, resetOnLocationChange, clearState, time }) => {
    const { t } = useTranslation();
    const clearStateRef = useRef(clearState);
    if (clearStateRef.current !== clearState) clearStateRef.current = clearState;
    const location = useLocation();
    const lastLocation = useRef();
    const [showReloadMsg, setShowReloadMsg] = useState(true);

    useEffect(() => {
        if (resetOnLocationChange && lastLocation.current && lastLocation.current !== location) {
            clearStateRef.current && clearStateRef.current();
        }
        lastLocation.current = location;
    }, [resetOnLocationChange, location]);

    useEffect(() => {
        const last_error_time = localStorage.getItem("last_error_time") ? new Date(localStorage.getItem("last_error_time")) : null;
        let timer;
        if (last_error_time) {
            if ((time.getTime() - last_error_time.getTime()) > 60000) {
                !showReloadMsg && setShowReloadMsg(true);
                let timeleft = 10;
                timer = setInterval(() => {
                    const el = document.getElementById("reload");
                    if (!el) {
                        clearInterval(timer);
                        return;
                    }
                    if (timeleft <= 0) {
                        if (el) el.innerHTML = t("error.reloadMsg") + timeleft + "s";
                        clearInterval(timer);
                        localStorage.setItem("last_error_time", time);
                        globalThis.location.reload();
                    } else {
                        if (el) el.innerHTML = t("error.reloadMsg") + timeleft + "s";
                    }
                    timeleft--;
                }, 1000);
            } else {
                setShowReloadMsg(false);
            }
        } else {
            !showReloadMsg && setShowReloadMsg(true);
            let timeleft = 10;
            timer = setInterval(() => {
                const el = document.getElementById("reload");
                if (!el) {
                    clearInterval(timer);
                    return;
                }
                if (timeleft <= 0) {
                    if (el) el.innerHTML = t("error.reloadMsg") + timeleft + "s";
                    clearInterval(timer);
                    localStorage.setItem("last_error_time", time);
                    globalThis.location.reload();
                } else {
                    if (el) el.innerHTML = t("error.reloadMsg") + timeleft + "s";
                }
                timeleft--;
            }, 1000);
        }

    //return () => timer && clearInterval(timer);
    }, []);

    return (
        <div>
            <h2>{t("error.msg")}</h2>
            <p>{t("error.id")}: {uuid}</p>
            {showReloadMsg &&
        <>
            <br />
            <p id="reload" />
        </>}
        </div>
    );
};

export { ShowError, sendError };
